import React from "react";
import classNames from "classnames";

import { Carousel, Testimonial } from "@molecules";
import { Container } from "@atoms";

const TestimonialCarousel = ({
  bgColor,
  testimonials,
  className: _className,
}) => {
  return (
    <section
      className={classNames(
        "py-16 md:py-32",
        {
          "bg-midnight": bgColor === "midnight",
        },
        _className
      )}
    >
      <Container variant="xl">
        <div className="px-4 md:px-8">
          <Carousel maxVisible={1} showInactive>
            {testimonials?.map(t => (
              <Testimonial key={t.uid} color={bgColor} {...t} />
            ))}
          </Carousel>
        </div>
      </Container>
    </section>
  );
};

TestimonialCarousel.defaultProps = {
  bgColor: "midnight",
};

export default TestimonialCarousel;
